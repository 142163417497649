import * as React from "react";
import {
  CafeAccess,
  CafeGallery,
  CafeInfo,
  Layout,
  PageTitle,
} from "../../components";
import Seo from "../../components/Seo";

const InagawaPage = () => {
  return (
    <Layout>
      <Seo title="INAGAWA" />
      <PageTitle
        title="emo TOKYO Inagawa"
        subTitle="猪名川にぽつり存在する焚き火を囲む非日常空間"
      />
      <CafeInfo
        place="inagawa"
        heading="猪名川にぽつり存在する焚き火を囲む非日常空間"
        description={
          <p className="mb-6 lg:mb-10 text-sm leading-loose lg:text-base">
            少し足を延ばした田園風景の残る郊外…
            <br />
            何も遮らない、どこまでも続く空の下、和の生活骨董をしつらえた古い土蔵の隣で、焚き火の揺らぎを見つめるひとときー。淡路店とはまた一風変わった独特のエモ＆チルな非日常空間があなたを包み込みます。
            <br />
            <br />
            薪の炎と石だけで焼き上げた風味豊かな４種の焼き芋。焚き火のお供にはコレ！とまさにぴったりな
            Chill
            Music（ギターの弾き語りもあり！？）とドリンク＆フードメニュー。
            <br />
            Chillの代名詞ともいえる水たばこ（シーシャ…当店ではニコチン抜きのものを採用）。
            <br />
            今日だけはピリリとした緊張感を捨てて、ゆるりとした時間をご満喫ください。
          </p>
        }
        reserveLink="https://airrsv.net/emotokyoreservation/calendar"
      />
      <CafeGallery place="inagawa" />
      <CafeAccess
        map="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13090.232584823718!2d135.3657751!3d34.8924402!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae228d31cbe98492!2semo%20TOKYO!5e0!3m2!1sja!2sjp!4v1642084367589!5m2!1sja!2sjp"
        name="焚き火Cafe&Bar emo TOKYO Inagawa"
        address="〒666-0252 兵庫県猪名川町広根町屋東21"
        mapUrl="https://goo.gl/maps/bbSwT7uWUiZW7iko7"
        tel="090-8529-5900"
        hours={
          <p className="block text-right">
            営業日　土・日・祝
            <br />
            予約あり　14:00〜23:00(L.O 21:30)
            <br />
            予約なし　16:00〜23:00(L.O 21:30)
            {/* Cafe　17:00〜22:00(L.O 21:30) */}
          </p>
        }
        seats={
          <p className="block text-right">
            焚き火ピット×5
            <br />
            各ピット　4〜5名
          </p>
        }
        system={
          <p className="block text-right">
            シートチャージ
            <br />
            <br />
            1,500円 / 人　2時間
          </p>
        }
        parking="5,6台"
        instagram="https://www.instagram.com/emo_tokyo_inagawa"
      />
    </Layout>
  );
};

export default InagawaPage;
